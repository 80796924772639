import About from "./components/sections/About";
import Contact from "./components/sections/Contact";
import Service from "./components/sections/Service";
import NavBar from "./layout/NavBar";
import { ChakraProvider } from "@chakra-ui/react";
import { Images, Icons } from "./constants";
import Header from "./components/sections/Hero";
import { useEffect, useState } from "react";
import NavBarMob from "./layout/NavBarMob";
import Footer from "./layout/Footer";
import AppMob from "./components/sections/AppMob";

function App() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    if (theme === "dark") document.documentElement.classList.add("dark");
    else document.documentElement.classList.remove("dark");
  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const [files, setFiles] = useState([]);

  const handleShowDetails = (path) => {
    const files = require.context(path, false, /\.jpg$/);
    const fileNames = files.keys().map((key) => key.split("/").pop());
    console.log(fileNames);
  };

  return (
    <ChakraProvider>
      <div
        className="App w-full overflow-hidden bg-white dark:bg-secondary-200"
        id="home"
      >
        <NavBar click={handleThemeSwitch} />

        <Header />

        <About />

        <Service />

        <AppMob />

        <Contact />

        <Footer />
      </div>
    </ChakraProvider>
  );
}

export default App;
