import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Icons } from "../../constants";
import { Spinner } from "@chakra-ui/react";

const ContactForm = () => {
  const [msg, setMsg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  function sendEmail(e) {
    setMsg("");
    setLoading(true);
    e.preventDefault();
    // Use your service ID and template ID
    const serviceID = "service_3dnxvtl";
    const templateID = "template_za8piak";

    // Replace with your user ID and template parameters
    const userID = "JoMKHbNDbHhemR0cT";
    const templateParams = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    // Send email using EmailJS
    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log("Email sent successfully:", response);
        setMsg("Message envoye");
        clear();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        setMsg("Message non envoye");
        setLoading(false);
      });
  }

  const clear = () => {
    setMessage("");
    setName("");
    setEmail("");
    setPhone("");
  };

  return (
    <form className="flex flex-col w-full" onSubmit={sendEmail}>
      <p
        htmlFor=""
        className="text-black bg-green-300 px-2 py-px rounded font-semibold text-xs mb-4"
      >
        {msg}
      </p>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        type="text"
        name="Name"
        id="name"
        required
        placeholder="Votre nom"
        className="border placeholder-secondary-200 text-gray-700 mt-4 rounded-md outline-none px-3 py-3 text-sm bg-bg-100"
      />

      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        name="Email"
        id="email"
        required
        placeholder="Votre email"
        className="border placeholder-secondary-200 text-gray-700 mt-4 rounded-md outline-none px-3 py-3 text-sm bg-bg-100"
      />

      <input
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        type="tel"
        name="Phone"
        id="phone"
        required
        placeholder="Votre numereo de telephone"
        className="border placeholder-secondary-200 text-gray-700 mt-4 rounded-md outline-none px-3 py-3 text-sm bg-bg-100"
      />

      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        name="Message"
        id="message"
        placeholder="Message"
        required
        cols="30"
        rows="10"
        className="border placeholder-secondary-200 text-gray-700 mt-4 rounded-md outline-none px-3 py-4 text-sm bg-bg-100 max-h-64 min-h-[20px] h-[140px]"
      ></textarea>

      {loading ? (
        <div className="flex items-center justify-center w-full mt-6">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      ) : (
        <button
          type="submit"
          className="m-auto md:mx-0 flex items-center rounded-md mt-4 bg-green-600 font-semibold py-4 px-8 w-fit hover:bg-green-800 transition duration-300 ease-in-out"
        >
          <p className="mr-4 text-white">Envoyer le message</p>
          <img src={Icons.Send} alt="" />
        </button>
      )}
    </form>
  );
};

export default ContactForm;
