import Apple from "../assets/icons/apple.svg";
import Android from "../assets/icons/android.svg";
import Fb from "../assets/icons/fb.svg";
import In from "../assets/icons/in.svg";
import Instagram from "../assets/icons/instagram.svg";
import Twitter from "../assets/icons/twitter.svg";
import Hotel from "../assets/icons/hotel.svg";
import RDVClinic from "../assets/icons/rdv-clinic.svg";
import RDV from "../assets/icons/rdv.svg";
import Reservation from "../assets/icons/reservation.svg";
import Article from "../assets/icons/article.svg";
import Build from "../assets/icons/build.svg";
import Delivery from "../assets/icons/delivery.svg";
import Location from "../assets/icons/location.svg";
import Other from "../assets/icons/other.svg";
import Service from "../assets/icons/service.svg";
import Play from "../assets/icons/play.svg";
import Download from "../assets/icons/download.svg";

export default {
  Apple,
  Android,
  Download,
  Fb,
  Play,
  In,
  Instagram,
  Twitter,
  Hotel,
  RDV,
  RDVClinic,
  Reservation,
  Article,
  Build,
  Delivery,
  Location,
  Other,
  Service,
};
