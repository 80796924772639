const Button = ({ name_btn, padding, handleBtn }) => {
  return (
    <button
      className={`text-white rounded-md bg-green-800 ${padding} 
                hover:bg-green-600 hover:bg-opacity-20 transition duration-300 ease-in`}
      onClick={handleBtn}
    >
      {name_btn}
    </button>
  );
};

export default Button;
