// import axios from 'axios'
// import fileDownload from 'js-file-download'
import Button from "../components/ui/Button";
import { useEffect, useState } from "react";
import { Switch } from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Spinner } from "@chakra-ui/react";
import { Icons, Logos } from "../constants";

const NavBar = ({ click }) => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <nav className="shadow-lg fixed w-full z-50 bg-gray-800">
      <div className="responsive w-full h-50 flex justify-between items-center py-4 text-white">
        <img
          src={Logos.Logo}
          alt=""
          className="w-40"
          data-aos="fade-out"
          data-aos-once="true"
        />
        <ul className="flex text-sm">
          <li
            data-aos="zoom-in"
            data-aos-once="true"
            className="px-4 py-2 cursor-pointer hover:text-green-400 hidden lg:flex items-center transition duration-300 ease-in text-white"
          >
            <a href="#home">Acceuil</a>
          </li>
          <li
            data-aos="zoom-in"
            data-aos-once="true"
            className="px-4 py-2 cursor-pointer hover:text-green-400 hidden lg:flex items-center transition duration-300 ease-in text-white"
          >
            <a href="#services">Nos Services</a>
          </li>
          <li
            data-aos="zoom-in"
            data-aos-once="true"
            className="px-4 py-2 cursor-pointer hover:text-green-400 hidden lg:flex items-center transition duration-300 ease-in text-white"
          >
            <a href="#app">App</a>
          </li>
          <li
            data-aos="zoom-in"
            data-aos-once="true"
            className="px-4 py-2 mr-10 cursor-pointer hover:text-green-400 hidden lg:flex items-center transition duration-300 ease-in text-white"
          >
            <a href="#contact">Contact</a>
          </li>
          <a href="#app" className="hidden md:block">
            <Button name_btn="Telecharger App" padding="px-3 py-2" />
          </a>
          <a href="#app" className="md:hidden bg-green-600 rounded-lg">
            <img src={Icons.Download} alt="" className="w-5 m-2" />
          </a>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
