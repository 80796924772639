import React, { useEffect, useState } from "react";
import ContactForm from "../form/ContactForm";
import Button from "../ui/Button";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Images, Logos, Icons } from "../../constants";

const Contact = () => {
  const [sendMsg, showSendMsg] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full">
      <div className="responsive relative py-10 mb-10 md:mb-0" id="contact">
        <div className="rounded-lg relative h-fit overflow-hidden">
          <img
            src={Images.BgTalk}
            alt=""
            className="w-full h-full absolute left-0 top-0 right-0 bottom-0 object-cover brightness-[.7]"
          />
          <div className="relative z-20 h-full p-8 md:p-20">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <h1 className="text-xl md:text-4xl font-bold text-white md:w-[400px] text-center md:text-left mb-10 md:mb-0">
                Travaillez avec frihali et devenir partenaire
              </h1>
              <button
                className="bg-white rounded-lg px-10 py-3 font-medium"
                onClick={() => showSendMsg(true)}
              >
                Parlons
              </button>
            </div>
            {sendMsg && (
              <div className="mt-6">
                <ContactForm />
              </div>
            )}
          </div>
        </div>
        <div className="md:grid grid-cols-12 ">
          <div className="col-span-6 p-4 md:p-8 bg-white rounded-xl z-30 mt-10 md:mt-0 relative"></div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
