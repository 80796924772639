import React from "react";
import { Icons, Images, Logos } from "../constants";

const Footer = () => {
  return (
    <div className="bg-gray-800 py-10">
      <div className="responsive">
        <div className="grid md:grid-cols-9">
          <div className="md:col-span-3">
            <img
              src={Logos.Logo}
              alt=""
              className="w-40 mb-3 mx-auto md:mx-0"
            />
            <p className="text-gray-200 mt-6 text-center md:text-left">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              aperiam soluta debitis voluptatibus blanditiis!
            </p>
            <ul className="flex items-center space-x-2 justify-center md:justify-start mt-4 w-full">
              <a
                href="https://www.facebook.com/profile.php?id=61552174407065&sfnsn=wa&mibextid=RUbZ1f"
                target="_blank"
              >
                <li className="border p-2 rounded-md">
                  <img src={Icons.Fb} alt="" className="w-7" />
                </li>
              </a>
              <li className="border p-2 rounded-md">
                <img src={Icons.In} alt="" className="w-7" />
              </li>
              <a
                href="https://www.instagram.com/frihali.fi.alger16?utm_source=qr&igsh=MWY0cDhxYWJ6c2c0dA=="
                target="_blank"
              >
                <li className="border p-2 rounded-md">
                  <img src={Icons.Instagram} alt="" className="w-7" />
                </li>
              </a>

              <li className="border p-2 rounded-md">
                <img src={Icons.Twitter} alt="" className="w-7" />
              </li>
            </ul>
          </div>
          <div className="md:col-span-2 flex justify-center">
            <div>
              <h1 className="text-xl font-semibold mb-3 text-white text-center md:text-left mt-10 md:mt-0">
                Entreprise
              </h1>
              <ul className="space-y-2 ">
                <li className="text-gray-200 text-center md:text-left">
                  Acceuil
                </li>
                <li className="text-gray-200 text-center md:text-left">
                  Services
                </li>
                <li className="text-gray-200 text-center md:text-left">App</li>
                <li className="text-gray-200 text-center md:text-left">
                  Contact
                </li>
              </ul>
            </div>
          </div>

          <div className="md:col-span-2 flex justify-center">
            <div>
              <h1 className="text-xl font-semibold mb-3 text-white text-center md:text-left mt-10 md:mt-0">
                Contact
              </h1>
              <ul className="space-y-2">
                <li>
                  <img src="" alt="" />
                  <p className="text-gray-200 text-center md:text-left">
                    contact@frihalifialger.net
                  </p>
                </li>
                <li>
                  <a href="tel:+213770778293">
                    <img src="" alt="" />
                    <p className="text-gray-200 text-center md:text-left">
                      +(213) 770 778 293
                    </p>
                  </a>
                </li>
                <li>
                  <img src="" alt="" />
                  <p className="text-gray-200 text-center md:text-left">
                    Alger
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:col-span-2 flex flex-col items-end">
            <div>
              <h1 className="text-xl font-semibold mb-3 text-white text-center md:text-left mt-10 md:mt-0">
                Scan Qr code
              </h1>
              <img src={Images.QrCode} alt="" className="w-36" />
            </div>
          </div>
        </div>
        <div className="mt-10 flex items-center justify-between border-t border-gray-300 pt-6">
          <div className="md:flex items-center space-x-4 hidden">
            <div
              href="#contact"
              className="flex items-center space-x-4 rounded-lg px-8 py-3 capitalize w-fit border border-white text-white bg-green-700 bg-opacity-40 cursor-pointer hover:bg-opacity-80 transition-all duration-300"
            >
              <img src={Icons.Apple} alt="" className="w-7" />
              <div className="">
                <h3 className="font-medium text-white text-sm">
                  télécharger pour IOS
                </h3>
              </div>
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.datamindx.afrihalifialger"
              target="_blank"
              className="flex items-center space-x-4 rounded-lg px-8 py-3 capitalize w-fit border border-white bg-green-600 bg-opacity-50 cursor-pointer hover:bg-opacity-80 transition-all duration-300"
            >
              <img src={Icons.Android} alt="" className="w-7" />
              <div className="">
                <h3 className="font-medium text-white text-sm">
                  télécharger pour Android
                </h3>
              </div>
            </a>
          </div>
          <div>
            <p className="text-white text-center md:text-left">
              Copyright © 2024 Frihali. Tous droits réservés.{" "}
            </p>
            <p className="text-white text-center md:text-left text-sm">
              created by{" "}
              <a
                className="text-green-600 font-medium cursor-pointer"
                target="_blank"
                href="https://www.linkedin.com/in/mohamed-benrabah-522992191/"
              >
                B.Mohamed
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
