import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Images } from "../../constants";

const About = () => {
  const [select, setSelect] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="mt-20 responsive grid md:grid-cols-2 gap-10">
      <div>
        <h1 className="text-lg md:text-4xl font-semibold  first-letter:capitalize text-center md:text-left mb-6">
          à propos de nous
        </h1>
        <p className="text-center md:text-left text-gray-500 text-sm md:text-base">
          L'application frihali fi Alger est la première application en Algérie
          qui facilite la vie du client et est accessible à toutes les
          catégories de la société et fournit de multiples services dont le
          principe est que nous agissons pour le compte du client au niveau
          d'Alger pour épargner lui la peine de bouger et la souffrance qui
          s'ensuit, et cela est au niveau
        </p>
        <ul className="grid grid-cols-1 mt-4 gap-2">
          <li className="flex items-center space-x-2 mx-0">
            <div className="bg-green-600 rounded-full w-4 h-4"></div>
            <p className="text-gray-500 text-sm md:text-base">
              Institutions publiques et privées
            </p>
          </li>
          <li className="flex items-center space-x-2 mx-0">
            <div className="bg-green-600 rounded-full w-4 h-4"></div>
            <p className="text-gray-500 text-sm md:text-base">
              tous les dealers et particuliers
            </p>
          </li>
          <li className="flex items-center space-x-2 mx-0">
            <div className="bg-green-600 rounded-full w-4 h-4"></div>
            <p className="text-gray-500 text-sm md:text-base">
              Médecins et cliniques privées
            </p>
          </li>
          <li className="flex items-center space-x-2 mx-0">
            <div className="bg-green-600 rounded-full w-4 h-4"></div>
            <p className="text-gray-500 text-sm md:text-base">
              Vendeurs et prestataires de services
            </p>
          </li>
          <li className="flex items-center space-x-2 mx-0">
            <div className="bg-green-600 rounded-full w-4 h-4"></div>
            <p className="text-gray-500 text-sm md:text-base">
              les personnes physiques
            </p>
          </li>
        </ul>

        <p className="mt-6 text-gray-500 text-center md:text-left text-sm md:text-base">
          Il suffit au client de télécharger l'application sur le Play Store et
          d'enregistrer son numéro et sa wilaya et son adresse, puis il sera
          contacté par un responsable de la communication afin de coordonner la
          fourniture du service sans que le client se déplace, et en cette
          fois-là, il gagnera du temps, des efforts et de l'argent......
        </p>
      </div>
      <img src={Images.About} alt="" className="hidden md:block" />
    </div>
  );
};

export default About;
