import { useEffect, useRef, useState } from "react";
import Button from "../ui/Button";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Typewriter } from "react-simple-typewriter";
import { Spinner } from "@chakra-ui/react";

import { Images, Icons, Videos } from "../../constants";
// import Typed from 'react-typed';

const Hero = () => {
  const videoRefApp = useRef(null);
  const videoRefAppMob = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const playVid = () => {
    if (videoRefApp && videoRefApp.current) {
      if (isPlaying) {
        setIsPlaying(false);
        videoRefApp.current.pause();
      } else {
        setIsPlaying(true);
        videoRefApp.current.play();
      }
    }
  };

  const playVidMob = () => {
    if (videoRefAppMob && videoRefAppMob.current) {
      if (isPlaying) {
        setIsPlaying(false);
        videoRefAppMob.current.pause();
      } else {
        setIsPlaying(true);
        videoRefAppMob.current.play();
      }
    }
  };

  return (
    <>
      <div className="bg-green-600 relative">
        <img
          src={Images.Cover}
          alt=""
          className="absolute left-0 top-0 bottom-0 right-0 object-cover h-screen 2xl:h-[1000px] w-full"
        />
        <div
          className="flex flex-col lg:flex-row items-center lg:justify-between w-full responsive h-screen 2xl:h-[1000px] overflow-hidden relative"
          id="home"
        >
          <div className="lg:w-[50%] flex flex-col justify-center mt-20 lg:mt-10 space-y-6 h-full">
            <h1 className="text-secondary-100 font-extrabold text-2xl sm:text-3xl lg:text-5xl 2xl:text-6xl w-[250px] md:w-[350px]  py-1 capitalize mt-8 2xl:mb-4 text-white !leading-tight">
              Frihali fi alger app
            </h1>
            <p
              data-aos="fade-up"
              data-aos-once="true"
              className="text-white text-xs sm:text-sm lg:text-lg font-light md:leading-loose"
            >
              Où que vous soyez en dehors d'Alger et même sur Alger .Frihali fi
              Alger facilite la vie au clients et offre les différent services ;
              notamment celui d'agir a la place du clients au niveau d'Alger
              afin de lui évité le déplacement et économiser le temps et
              l'argent ; aussi nous agissons également A la visualisation des
              véhicule et immobilier et toute marchandises que l'on repère sur
              les sites web et les réseaux sociaux .
            </p>
            <div
              className="flex flex-col lg:flex-row lg:items-center space-y-6 md:space-y-2 lg:space-y-0 lg:space-x-6 2xl:pt-10"
              data-aos="fade-up"
              data-aos-once="true"
            >
              <div
                href="#contact"
                className="order-2 !mt-4 md:!mt-0 md:order-1 rounded-lg px-8 py-3 flex items-center space-x-4 capitalize bg-gray-800 hover:bg-opacity-0 border border-gray-800 hover:border-white transition-all duration-200 text-white cursor-pointer"
              >
                <img src={Icons.Apple} alt="" className="w-8 lg:w-12" />
                <div className="">
                  <h3 className="text-sm font-medium text-white">
                    télécharger pour
                  </h3>
                  <p className="font-extrabold text-xl lg:text-2xl text-white">
                    IOS
                  </p>
                </div>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.datamindx.afrihalifialger"
                target="_blank"
                className="order-1 md:order-2 rounded-lg px-8 py-3 capitalize flex items-center space-x-4 border border-white cursor-pointer hover:border-gray-800 hover:bg-gray-800 transition-all duration-300"
              >
                <img src={Icons.Android} alt="" className="w-12" />
                <div className="">
                  <h3 className="text-sm font-medium text-white">
                    télécharger pour
                  </h3>
                  <p className="font-extrabold text-2xl text-white">Android</p>
                </div>
              </a>
            </div>
          </div>
          <div className="mt-10 md:mt-0 absolute top-[85px] 2xl:top-[150px] right-[88px] bottom-0 hidden lg:block">
            <video
              ref={videoRefApp}
              type="video/mp4"
              src={require("../../assets/videos/vid.mp4")}
              loop
              className="w-[280px] h-[535px] 2xl:w-[380px] 2xl:h-[730px] object-cover rounded-3xl 2xl:rounded-[35px]"
            ></video>
          </div>
          <div className="mt-10 md:mt-0 absolute top-20 2xl:top-[143px] right-20 bottom-0 hidden lg:block">
            <div className="flex z-40 cursor-pointer absolute left-0 right-0 top-0 bottom-0 items-center justify-center text-white ">
              <div
                className={`${
                  !isPlaying && "bg-black"
                } bg-opacity-40 rounded-full w-20 h-20 flex items-center justify-center`}
                onClick={playVid}
              >
                {!isPlaying && (
                  <img
                    className="cursor-pointer w-1/2 mx-auto"
                    src={Icons.Play}
                    alt=""
                  />
                )}
              </div>
            </div>
            <img
              src={Images.Mockup}
              alt=""
              className="relative z-30 w-[300px] h-[550px] 2xl:w-[400px] 2xl:h-[750px] object-fill"
            />
          </div>
        </div>
      </div>

      <div className="responsive md:hidden relative h-[600px]">
        <div className="mt-10 md:mt-0 absolute top-[48px] left-1 right-0 bottom-0 flex justify-center">
          <video
            ref={videoRefAppMob}
            type="video/mp4"
            loop
            src={require("../../assets/videos/vid.mp4")}
            className="w-[282px] h-[535px] 2xl:w-[380px] 2xl:h-[730px] object-cover rounded-[38px]"
          ></video>
        </div>
        <div className="mt-10 md:mt-0 absolute top-10 left-0 right-0 bottom-0 flex justify-center">
          <div className="z-40 cursor-pointer absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center text-white ">
            {!isPlaying && (
              <div className="bg-black bg-opacity-40 rounded-full w-20 h-20 flex items-center justify-center">
                <img
                  className="cursor-pointer w-1/2 mx-auto"
                  src={Icons.Play}
                  alt=""
                  onClick={playVidMob}
                />
              </div>
            )}
          </div>
          <img
            src={Images.Mockup}
            alt=""
            className="relative z-30 w-[300px] h-[550px] 2xl:w-[400px] 2xl:h-[750px] object-fill"
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
