import React from "react";
import { Icons } from "../../constants";

const AppMob = () => {
  return (
    <div className="grid md:grid-cols-2 gap-x-12 gap-y-8 responsive md:pt-10 md:pb-10 mb-10">
      <div className="rounded-xl bg-green-100 shadow-lg p-8">
        <h3 className="font-bold text-gray-700 text-3xl">IOS</h3>
        <p className="text-gray-500 mt-2 mb-6">
          Téléchargez l'application pour IOS dès aujourd'hui, c'est gratuit.
        </p>
        <div
          href="#contact"
          className="order-2 !mt-4 md:!mt-0 md:order-1 rounded-lg px-8 py-3 flex items-center space-x-4 capitalize bg-gray-800 hover:bg-opacity-80 border border-gray-800 transition-all duration-200 text-white cursor-pointer"
        >
          <img src={Icons.Apple} alt="" className="w-8 md:w-12" />
          <div className="">
            <h3 className="text-xs md:text-sm font-medium text-white">
              télécharger pour
            </h3>
            <p className="font-extrabold text-lg md:text-2xl text-white">IOS</p>
          </div>
        </div>
      </div>
      <a
        className="rounded-xl bg-green-100 shadow-lg p-8"
        href="https://play.google.com/store/apps/details?id=com.datamindx.afrihalifialger"
        target="_blank"
      >
        <h3 className="font-bold text-gray-700 text-3xl">Android</h3>
        <p className="text-gray-500 mt-2 mb-6">
          Téléchargez l'application pour Android dès aujourd'hui, c'est gratuit.
        </p>
        <div
          href="#contact"
          className="order-2 !mt-4 md:!mt-0 md:order-1 rounded-lg px-8 py-3 flex items-center space-x-4 capitalize bg-gray-800 hover:bg-opacity-80 border border-gray-800 transition-all duration-200 text-white cursor-pointer"
        >
          <img src={Icons.Android} alt="" className="w-8 md:w-12" />
          <div className="">
            <h3 className="text-xs md:text-sm font-medium text-white">
              télécharger pour
            </h3>
            <p className="font-extrabold text-lg md:text-2xl text-white">
              Android
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default AppMob;
