import React, { useEffect } from "react";
// import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { Icons } from "../../constants";

const ic = [
  Icons.RDV,
  Icons.RDVClinic,
  Icons.Hotel,
  Icons.Reservation,
  Icons.Delivery,
  Icons.Article,
  Icons.Build,
  Icons.Location,
  Icons.Service,
  Icons.Other,
];

const CardService = ({ service, idx }) => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div data-aos="zoom-in" data-aos-once="true">
      <div className="!py-6 group hover:-translate-y-4 hover:scale-105 hover:border-b-4 hover:border-green-600 transition ease-in-out duration-500 group rounded-lg px-8 md:py-4 h-[250px] md:h-[320px] shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] cursor-pointer flex flex-col justify-start items-center">
        <div className="bg-green-200 rounded-full w-[70px] h-[70px] flex items-center justify-center">
          <img src={ic[idx]} alt="" className="w-[30px] object-cover" />
        </div>
        <div>
          <h2 className="text-md md:text-lg mb-3 text-gray-700 font-bold uppercase text-center mt-6">
            {service.title}
          </h2>
          <p className="text-xs md:text-sm text-center">
            {service.description.slice(0, 100)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardService;
